// @autogenerated


export default [
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport FieldsetBlock from \"@govtnz/ds/build/mustache/FieldsetBlock.mustache\";\nimport H1 from \"@govtnz/ds/build/mustache/H1.mustache\";\nimport InputBlock from \"@govtnz/ds/build/mustache/InputBlock.mustache\";\n\n// The variables FieldsetBlock, H1, InputBlock are all strings that are mustache templates.\n\nexport default `${Mustache.render(FieldsetBlock, {\n  id: `addressId`,\n  legend: `${Mustache.render(H1, {\n    \"styleSize=large\": true,\n    styleSize: true,\n    children: `What is your address?`\n  })}`,\n  children: `${Mustache.render(InputBlock, {\n    type: `text`,\n    label: `Street address`,\n    name: `streetAddress`,\n    id: `streetAddressId`,\n    autoComplete: `street-address`\n  })}${Mustache.render(InputBlock, {\n    type: `text`,\n    label: `Town or city`,\n    name: `town`,\n    id: `townId`,\n    autoComplete: `address-level2`\n  })}${Mustache.render(InputBlock, {\n    type: `text`,\n    label: `Postcode`,\n    name: `postcode`,\n    id: `postcodeId`,\n    width: `5`,\n    autoComplete: `postal-code`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-js/FieldsetBlock\";\nimport \"@govtnz/ds/build/css/FieldsetBlock.css\"; // or @govtnz/ds/build/scss/FieldsetBlock.scss\nimport H1 from \"@govtnz/ds/build/react-js/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport InputBlock from \"@govtnz/ds/build/react-js/InputBlock\";\nimport \"@govtnz/ds/build/css/InputBlock.css\"; // or @govtnz/ds/build/scss/InputBlock.scss\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  >\n    <InputBlock\n      type=\"text\"\n      label=\"Street address\"\n      name=\"streetAddress\"\n      id=\"streetAddressId\"\n      autoComplete=\"street-address\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Town or city\"\n      name=\"town\"\n      id=\"townId\"\n      autoComplete=\"address-level2\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Postcode\"\n      name=\"postcode\"\n      id=\"postcodeId\"\n      width=\"5\"\n      autoComplete=\"postal-code\"\n    />\n  </FieldsetBlock>\n);\n",
    "react-ts": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-ts/FieldsetBlock\";\nimport \"@govtnz/ds/build/css/FieldsetBlock.css\"; // or @govtnz/ds/build/scss/FieldsetBlock.scss\nimport H1 from \"@govtnz/ds/build/react-ts/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\nimport InputBlock from \"@govtnz/ds/build/react-ts/InputBlock\";\nimport \"@govtnz/ds/build/css/InputBlock.css\"; // or @govtnz/ds/build/scss/InputBlock.scss\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  >\n    <InputBlock\n      type=\"text\"\n      label=\"Street address\"\n      name=\"streetAddress\"\n      id=\"streetAddressId\"\n      autoComplete=\"street-address\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Town or city\"\n      name=\"town\"\n      id=\"townId\"\n      autoComplete=\"address-level2\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Postcode\"\n      name=\"postcode\"\n      id=\"postcodeId\"\n      width=\"5\"\n      autoComplete=\"postal-code\"\n    />\n  </FieldsetBlock>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-js-styled-components/FieldsetBlock\";\nimport H1 from \"@govtnz/ds/build/react-js-styled-components/H1\";\nimport InputBlock from \"@govtnz/ds/build/react-js-styled-components/InputBlock\";\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  >\n    <InputBlock\n      type=\"text\"\n      label=\"Street address\"\n      name=\"streetAddress\"\n      id=\"streetAddressId\"\n      autoComplete=\"street-address\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Town or city\"\n      name=\"town\"\n      id=\"townId\"\n      autoComplete=\"address-level2\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Postcode\"\n      name=\"postcode\"\n      id=\"postcodeId\"\n      width=\"5\"\n      autoComplete=\"postal-code\"\n    />\n  </FieldsetBlock>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-ts-styled-components/FieldsetBlock\";\nimport H1 from \"@govtnz/ds/build/react-ts-styled-components/H1\";\nimport InputBlock from \"@govtnz/ds/build/react-ts-styled-components/InputBlock\";\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  >\n    <InputBlock\n      type=\"text\"\n      label=\"Street address\"\n      name=\"streetAddress\"\n      id=\"streetAddressId\"\n      autoComplete=\"street-address\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Town or city\"\n      name=\"town\"\n      id=\"townId\"\n      autoComplete=\"address-level2\"\n    />\n    <InputBlock\n      type=\"text\"\n      label=\"Postcode\"\n      name=\"postcode\"\n      id=\"postcodeId\"\n      width=\"5\"\n      autoComplete=\"postal-code\"\n    />\n  </FieldsetBlock>\n);\n",
    "vue-js": "<template>\n  <fieldset-block id=\"addressId\" legend=\"What is your address?\">\n    <input-block\n      type=\"text\"\n      label=\"Street address\"\n      name=\"streetAddress\"\n      id=\"streetAddressId\"\n      autoComplete=\"street-address\"\n    />\n    <input-block\n      type=\"text\"\n      label=\"Town or city\"\n      name=\"town\"\n      id=\"townId\"\n      autoComplete=\"address-level2\"\n    />\n    <input-block\n      type=\"text\"\n      label=\"Postcode\"\n      name=\"postcode\"\n      id=\"postcodeId\"\n      width=\"5\"\n      autoComplete=\"postal-code\"\n    />\n  </fieldset-block>\n</template>\n<script>\nimport Vue from \"vue\";\nimport FieldsetBlock from \"@govtnz/ds/build/vue-js/FieldsetBlock.vue\";\nimport H1 from \"@govtnz/ds/build/vue-js/H1.vue\";\nimport InputBlock from \"@govtnz/ds/build/vue-js/InputBlock.vue\";\n\nexport default {\n  components: {\n    \"fieldset-block\": FieldsetBlock,\n    \"c-h1\": H1,\n    \"input-block\": InputBlock\n  }\n};\n</script>\n",
    "vue-ts": "<template>\n  <fieldset-block id=\"addressId\" legend=\"What is your address?\">\n    <input-block\n      type=\"text\"\n      label=\"Street address\"\n      name=\"streetAddress\"\n      id=\"streetAddressId\"\n      autoComplete=\"street-address\"\n    />\n    <input-block\n      type=\"text\"\n      label=\"Town or city\"\n      name=\"town\"\n      id=\"townId\"\n      autoComplete=\"address-level2\"\n    />\n    <input-block\n      type=\"text\"\n      label=\"Postcode\"\n      name=\"postcode\"\n      id=\"postcodeId\"\n      width=\"5\"\n      autoComplete=\"postal-code\"\n    />\n  </fieldset-block>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport FieldsetBlock from \"@govtnz/ds/build/vue-ts/FieldsetBlock.vue\";\nimport H1 from \"@govtnz/ds/build/vue-ts/H1.vue\";\nimport InputBlock from \"@govtnz/ds/build/vue-ts/InputBlock.vue\";\n\nexport default {\n  components: {\n    \"fieldset-block\": FieldsetBlock,\n    \"c-h1\": H1,\n    \"input-block\": InputBlock\n  }\n};\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: FieldsetBlock.css, H1.css, InputBlock.css\nOR in Sass (SCSS): FieldsetBlock.scss, H1.scss, InputBlock.scss\n-->\n<div class=\"g-fieldsetBlock-form-group\">\n  <fieldset class=\"g-fieldsetBlock-fieldset\">\n    <legend class=\"g-fieldsetBlock-fieldset__legend\">\n      <h1 class=\"g-heading g-heading-l\">What is your address?</h1>\n    </legend>\n    <div>\n      <div class=\"g-inputBlock-form-group\">\n        <label class=\"g-inputBlock-label\" for=\"streetAddressId\">\n          Street address\n        </label>\n        <input\n          class=\"g-inputBlock-input\"\n          id=\"streetAddressId\"\n          name=\"streetAddress\"\n          type=\"text\"\n          autocomplete=\"street-address\"\n        />\n      </div>\n      <div class=\"g-inputBlock-form-group\">\n        <label class=\"g-inputBlock-label\" for=\"townId\"> Town or city </label>\n        <input\n          class=\"g-inputBlock-input\"\n          id=\"townId\"\n          name=\"town\"\n          type=\"text\"\n          autocomplete=\"address-level2\"\n        />\n      </div>\n      <div class=\"g-inputBlock-form-group\">\n        <label class=\"g-inputBlock-label\" for=\"postcodeId\"> Postcode </label>\n        <input\n          class=\"g-inputBlock-input g-inputBlock-input--width-5\"\n          id=\"postcodeId\"\n          name=\"postcode\"\n          type=\"text\"\n          autocomplete=\"postal-code\"\n        />\n      </div>\n    </div>\n  </fieldset>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"FieldsetBlock.html.twig\"  with {'id':'addressId'} only %}{% block legend %}{% embed \"H1.html.twig\"  with {'styleSize':'large', 'children':'What is your address?'} only %}{% endembed %}{% endblock %}{% block children %}{% embed \"InputBlock.html.twig\"  with {'type':'text', 'label':'Street address', 'name':'streetAddress', 'id':'streetAddressId', 'autoComplete':'street-address'} only %}{% endembed %}\n{% embed \"InputBlock.html.twig\"  with {'type':'text', 'label':'Town or city', 'name':'town', 'id':'townId', 'autoComplete':'address-level2'} only %}{% endembed %}\n{% embed \"InputBlock.html.twig\"  with {'type':'text', 'label':'Postcode', 'name':'postcode', 'id':'postcodeId', 'width':'5', 'autoComplete':'postal-code'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport FieldsetBlock from \"@govtnz/ds/build/mustache/FieldsetBlock.mustache\";\nimport H1 from \"@govtnz/ds/build/mustache/H1.mustache\";\n\n// The variables FieldsetBlock, H1 are all strings that are mustache templates.\n\nexport default `${Mustache.render(FieldsetBlock, {\n  id: `addressId`,\n  legend: `${Mustache.render(H1, {\n    \"styleSize=large\": true,\n    styleSize: true,\n    children: `What is your address?`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-js/FieldsetBlock\";\nimport \"@govtnz/ds/build/css/FieldsetBlock.css\"; // or @govtnz/ds/build/scss/FieldsetBlock.scss\nimport H1 from \"@govtnz/ds/build/react-js/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  />\n);\n",
    "react-ts": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-ts/FieldsetBlock\";\nimport \"@govtnz/ds/build/css/FieldsetBlock.css\"; // or @govtnz/ds/build/scss/FieldsetBlock.scss\nimport H1 from \"@govtnz/ds/build/react-ts/H1\";\nimport \"@govtnz/ds/build/css/H1.css\"; // or @govtnz/ds/build/scss/H1.scss\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  />\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-js-styled-components/FieldsetBlock\";\nimport H1 from \"@govtnz/ds/build/react-js-styled-components/H1\";\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  />\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport FieldsetBlock from \"@govtnz/ds/build/react-ts-styled-components/FieldsetBlock\";\nimport H1 from \"@govtnz/ds/build/react-ts-styled-components/H1\";\n\nexport default () => (\n  <FieldsetBlock\n    id=\"addressId\"\n    legend={<H1 styleSize=\"large\">What is your address?</H1>}\n  />\n);\n",
    "vue-js": "<template>\n  <fieldset-block id=\"addressId\" legend=\"What is your address?\" />\n</template>\n<script>\nimport Vue from \"vue\";\nimport FieldsetBlock from \"@govtnz/ds/build/vue-js/FieldsetBlock.vue\";\nimport H1 from \"@govtnz/ds/build/vue-js/H1.vue\";\n\nexport default { components: { \"fieldset-block\": FieldsetBlock, \"c-h1\": H1 } };\n</script>\n",
    "vue-ts": "<template>\n  <fieldset-block id=\"addressId\" legend=\"What is your address?\" />\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport FieldsetBlock from \"@govtnz/ds/build/vue-ts/FieldsetBlock.vue\";\nimport H1 from \"@govtnz/ds/build/vue-ts/H1.vue\";\n\nexport default { components: { \"fieldset-block\": FieldsetBlock, \"c-h1\": H1 } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: FieldsetBlock.css, H1.css\nOR in Sass (SCSS): FieldsetBlock.scss, H1.scss\n-->\n<div class=\"g-fieldsetBlock-form-group\">\n  <fieldset class=\"g-fieldsetBlock-fieldset\">\n    <legend class=\"g-fieldsetBlock-fieldset__legend\">\n      <h1 class=\"g-heading g-heading-l\">What is your address?</h1>\n    </legend>\n    <div>Fieldset contents</div>\n  </fieldset>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"FieldsetBlock.html.twig\"  with {'id':'addressId'} only %}{% block legend %}{% embed \"H1.html.twig\"  with {'styleSize':'large', 'children':'What is your address?'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  }
]